.root {
  color:       #FFFFFF;
}

.title{
  color:       #FFFFFF;
  text-align:  center;
  font-weight: bold;
}

.form {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: 50% auto;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

.layout_1_line_1_left {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        1;
}

.layout_1_line_1_right {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        1;
}

.layout_1_line_2_left {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        2;
}

.layout_1_line_2_right {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        2;
}

.layout_1_line_3 { 
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        3;
}

.layout_1_line_4 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        4;
}

.layout_1_line_5_left {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        5;
}

.layout_1_line_5_right {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        5;
}

.layout_1_line_6_left {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        6;
}

.layout_1_line_6_right {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        6;
}

.layout_1_line_7 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        7;
}

.layout_2_line_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        1;
}

.layout_2_line_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        2;
}

.layout_2_line_3 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        3;
}

.layout_2_line_4 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        4;
}

.layout_2_line_5 { 
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        5;
}

.layout_2_line_6 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        6;
}

.layout_2_line_7 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        7;
}

.layout_2_line_8 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        8;
}

.layout_2_line_9 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        9;
}

.layout_2_line_10 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        10;
}

.layout_2_line_11 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        11;
}

.buttons {

  margin: auto;
}

.link {

  color: #FFFFFF;
}

.grid_info
 {
  width:                 100%;
  pading:                0px;
  margin:                0;
  font-size:             16px;
  text-align:            left;
  
  display:               grid;
  grid-template-columns: 170px auto;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

.buttons_block {
  margin: auto;
}
