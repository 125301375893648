@import '../../../config.module.scss';

.root {
}

.window {

  display:       inline-block;

  width:         95vw;
  height:        fit-content;
  max-width:     600px;
  max-height:    95vh;
  
  margin:        auto;
  padding:       10px;

  position:      fixed;
  top:           50%;
  left:          50%;
  transform:     translate(-50%, -50%);
  
  overflow:      auto;
  
  z-index:       9002;
  
  border-radius: 5px;
  border-width:  1px;
  border-style:  solid;
  border-color:  #FFFFFF;
  outline:       none;

  background:    #101010;
}

.block_informations {

  height:        400px;
}

.block_toggle {

  display:         flex;
  flex-direction:  row;
  text-align :     center;
  justify-content: center;
  vertical-align:  middle; 
  align-items:     center;
  color:           #FFFFFF;;
  
}

.text_rgpd {

  width:         100%;
  text-align:    justify;
  color:         #FFFFFF;
  font-size:     $block-text-size-1
}


.link{

  color:         #FFFFFF;
  font-size:     $block-text-size-1
}

.block_buttons { 

  text-align :   center;	
}
    
.button {
 
  height:        30px;
  width:         fit-content;
  font-size:     $block-text-size-2;
  padding:       0 5px;
  margin:        5px;	
}

@media only screen and (max-width: 870px) {

.window {
  padding:       5px;
}

.title {

  font-size:     16px;
}

.text_rgpd {

  font-size:     14px;
}

.link{

  font-size:     14px;
}

.block_buttons { 

  font-size:     16px;
}
    
.button {
 
  height:        30px;
  width:         fit-content;
  font-size:     $block-text-size-2;
  padding:       0 5px;
  margin:        5px;	
}

.block_informations {

  height:        fit-content;
}

}