@import '../../../../../config.module.scss';

.root {
}

.window {
  display:       inline-block;

  width:         90%;
  height:        fit-content;
  max-width:     350px;

  
  margin:        auto;
  padding:       40px;

  position:      fixed;
  top:           50%;
  left:          50%;
  transform:     translate(-50%, -50%);
  
  z-index:       $z-index-window-cookies-manager;
  
  overflow:      auto;

  border-radius: 20px;
  border-width:  1px;
  border-style:  solid;
  border-color:  $block-border-color;
  outline:       none;

  background:    $block-background-color
}

.text {
  width:         100%;
  color:         $block-text-color-1;
  text-align:    center;
  text-justift:  center;
  font-size:     20px;
}

.text2 {
  color:         $block-text-color-1;
  font-size:     $block-text-size-1;
}

.block_buttons { 

  text-align :   center;	
  font-size:     $block-text-size-2;
}

.button {
  height:        30px;
  width:         fit-content;
  font-size:     $block-text-size-2;
  padding:       0 5px;
  margin:        5px;	
}

.message_ack{
  color:#00FF00
}

.message_error{
  color:#FF0000
}
