.root {

 width:   fit-content; /*364px;/*390px; /*302px;*/
 display: inline-block;
 height:  fit-content; /*204px; /*232px; /*180px;*/
 padding: 5px;
}

.title {

  color: #FFFFFF;
  font-size: 20px;
}

.image {

  width: 364px; /*282px;*/
  height: 204px; /*158px;*/

}

.rel {
  position: relative;
}

.size1 {
  width: 364px;
  height: 204px;

}

.size2 {
 width: 90vw;
 height: auto;

}