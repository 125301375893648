.root {
  background-color: #000000;
  height: fit-content;
}


.part1 {

  height: fit-content;   /* 850px; */
  width: 100%;
  background-image: url("https://lovelightfactory.fr/images/fond_avec_gradient.jpg");
  background-repeat: no-repeat;
}

.part2 {

  height: fit-content;
}


.contain {
 width: 100%;
}


.grid
 {
  height:                fit-content;
  
  pading:                0px;
  margin:                10px;
  margin-left:           200px;
  margin-right:          200px;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

.block_1_1 {
  grid-column:     1/6;
  grid-row:        1;
}

.block_1_2 {
  grid-column:     7/15;
  grid-row:        1;
	vertical-align: middle
}

.inter_1 {
  grid-column:     1/16;
  grid-row:        2;
}

.block_2_1 {
  grid-column:     3/11;
  grid-row:        3;

}

.block_2_2 {
  grid-column:     12/17;
  grid-row:        3;
   display: flex;
    justify-content: flex-end;
  }

.inter_2 {
  grid-column:     1/16;
  grid-row:        4;
}

.block_3_1 {
  grid-column:     1/6;
  grid-row:        5;
}

.block_3_2 {
  grid-column:     7/15;
  grid-row:        5;
}

.inter_3 {
  grid-column:     1/16;
  grid-row:        6;
}

.block_4_1 {
  grid-column:     3/11;
  grid-row:        7;
}

.block_4_2 {
  grid-column:     12/17;
  grid-row:        7;
   display: flex;
    justify-content: flex-end;
  }
.inter_4 {
  grid-column:     1/16;
  grid-row:        6;
}


@media only screen and (max-width: 1100px) {


.grid
 {
  height:                fit-content;
  width:                 90%;
 
  pading:                0px;
  margin:                10px;
  margin-left:           auto;
  margin-right:          auto;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 100%;
  grid-gap:              10px;
  grid-template-rows:    auto;
}


.block_1_1 {
  grid-column:     1;
  grid-row:        1;
}

.block_1_2 {
  grid-column:     1;
  grid-row:        2;
	vertical-align: middle
}

.inter_1 {
  grid-column:     1;
  grid-row:        3;
}

.block_2_1 {
  grid-column:     1;
  grid-row:        4;

}

.block_2_2 {
  grid-column:     1;
  grid-row:        5;
   display: flex;
    justify-content: flex-end;
  }

.inter_2 {
  grid-column:     1;
  grid-row:        6;
}

.block_3_1 {
  grid-column:     1;
  grid-row:        7;
}

.block_3_2 {
  grid-column:     1;
  grid-row:        8;
}

.inter_3 {
  grid-column:     1;
  grid-row:        9;
}

.block_4_1 {
  grid-column:     1;
  grid-row:        10;
}

.block_4_2 {
  grid-column:     1;
  grid-row:        11;
   display: flex;
    justify-content: flex-end;
  }
  
.inter_4 {
  grid-column:     1;
  grid-row:        9;
}

}



.text {

  color: #FFFFFF
}

.block_text {

 margin: 0px;
}


.bold_italic {

  font-weight: bold; 
   font-style: italic;

}

@media only screen and (max-width: 1100px) {

  .block_text {

    text-align: left;
  }
}


.image {

margin: auto;
width: fit-content;
}

table {
    width: 100%; /* Largeur du tableau */
    background-color: #000000; /* Couleur de fond pour voir les bordures blanches */
    border-collapse: collapse; /* Pour éviter l'espace entre les bordures */
  }
  th, td {
    border: 1px solid black; /* Bordures blanches */
    padding: 10px; /* Espacement intérieur */
  }

.btn {

 width: fit-content;
 margin: auto;
}




.tab
 {
  height:                fit-content;
 
  pading:                0px;
  margin:                0px;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 21px 1fr;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

.tab_1_1 
 {
  grid-column:     1;
  grid-row:        1; 
 }

.tab_1_2 
 {
  grid-column:     2;
  grid-row:        1; 
 }

.tab_2_1 
 {
  grid-column:     1;
  grid-row:        2; 
 }

.tab_2_2 
 {
  grid-column:     2;
  grid-row:        2; 
 }

.tab_3_1 
 {
  grid-column:     1;
  grid-row:        3; 
 }

.tab_3_2 
 {
  grid-column:     2;
  grid-row:        3; 
 }

.tab_4_1 
 {
  grid-column:     1;
  grid-row:        4; 
 }

.tab_4_2 
 {
  grid-column:     2;
  grid-row:        4; 
 }

.tab_5_1 
 {
  grid-column:     1;
  grid-row:        5; 
 }

.tab_5_2 
 {
  grid-column:     2;
  grid-row:        5; 
 }

.tab_6_1 
 {
  grid-column:     1;
  grid-row:        6; 
 }

.tab_6_2 
 {
  grid-column:     2;
  grid-row:        6; 
 }

.icon {

  width: 20px;
  height: auto;
}


.price {

 font-size: 16px;
 font-weight: bold;
 width: fit-content;
 margin: auto;
}

.offer_title {
 font-size: 18px;
font-weight: normal;
 width: fit-content;
 margin: auto;
 color: #A1A1AA;

}

.offer_super_title {
 font-size: 24px;
font-weight: normal;
 width: fit-content;
 margin: auto;
 color: #FFFFFF;

}



.boldText {

 font-weight: bold;

}

.unbold {

font-weight: normal;
 font-size: 14px;
 font-style: italic;
 color: #A1A1AA;

}


.Masterclass {
 color: #FFFFFF;
 font-size: 20px;
 font-weight: bold;
 font-style: italic;
 width: fit-content;
 margin: auto;
}

.testimonials {

    width:                 90vw;
	height:        34rem;
	margin:auto;
	overflow: hidden

}

.centered_text {
 width: fit-content;
 margin:auto;
}





.offers
 {
  height:                fit-content;
 
  pading:                0px;
  margin:                0px;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 1f 1fr;
  grid-gap:              10px;
  grid-template-rows:    auto;
}


.free_block 
 {
  grid-column:     1/3;
  grid-row:        1; 
  height:          71px;
  border: 1px solid #A1A1AA;
  border-radius: 10px;
  
  padding-left:        20px;
  padding-right:       20px;
  padding-top:         23px;
  padding-bottom:      24px;
 }
 
/* CSS pour la plage de largeurs 1024-1250px */
@media only screen and (min-width: 1024px) and (max-width: 1250px) {
  .free_block {
    padding-top: 35px;
    padding-bottom: 36px;
	  height:          95px;

  }
}

/* CSS pour la plage de largeurs 767-851px */
@media only screen and (min-width: 767px) and (max-width: 851px) {
  .free_block {
    padding-top: 35px;
    padding-bottom: 36px;
	  height:          95px;
  }
}

.annual 
 {
  grid-column:     1;
  grid-row:        1; 
  
  border: 1px solid #A1A1AA;
  border-radius: 10px;
  width:         fit-content;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
 }
 
.mensual 
 {
  grid-column:     2;
  grid-row:        1; 
  
  border: 1px solid #A1A1AA;
  border-radius: 10px;
 }

.shift {

  height: 80px;
}



.btn {

 width: fit-content;
 margin: auto;
 margin-top: 10px;
}

