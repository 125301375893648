.root {

 width:   100%;
 height:  fit-content;
 display: inline-block;
 padding: 10px;
}

.contain {

 width:   fit-content;
 height:  fit-content;
 margin:  auto;
}

.grid {
  width:                 100%;
  pading:                0;
  margin:                0;
  align-items:           center;

  display:               grid;
  grid-template-columns: 150px 450px 70px 120px 70px;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

.item_image {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        1;
}

.item_name {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        1;
}

.privileges_premium {
  width:           100%;
  display:         flex;
  grid-column:     2/4;
  grid-row:        1;
}



.item_unit_price {
  width:           100%;
  display:         flex;
  grid-column:     3;
  grid-row:        1;
}

.quantity {
  width:           100%;
  display:         flex;
  grid-column:     4;
  grid-row:        1;
}

.item_price {
  width:           100%;
  display:         flex;
  grid-column:     5;
  grid-row:        1;
}

.text {

 color:  #FFFFFF;
}

.image {

  width:  150px;
  height: auto;
}

.centered_block {

 width: fit-content;
 margin: auto;
}

.right_align {

 text-align: right;
 margin-right: 0;
 margin-left: auto;
}

.big_message {

 color: #FFFFFF;
 font-size: 20px;
}


@media only screen and (max-width: 900px) {

.grid {
  width:                 95%;
  pading:                0;
  pading-bottom:         0px;
  margin:                auto;
  align-items:           center;

  display:               grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

.item_image {
  width:           80%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        1;
  text-align:      center;
}

.item_name {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        2;
  text-align:      center;
}

.item_unit_price {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        3;
}

.quantity {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        3;
}

.item_price {
  width:           100%;
  display:         flex;
  grid-column:     3;
  grid-row:        3;
}
}
