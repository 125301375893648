.root {
  width: 100%;
  position: relative; 
  height: fit-content;
  color: #FFFFFF;
  margin-top: 0px;
  margin-bottom: 20px;
  transition: background-image 1s ease;
  overflow: hidden;  
  background-color: #141414;
}

.ad {
  position: absolute; 
  left: 5%;          
  padding: 0;
  margin: 0;
  color: white; 
  width: 33%; 
  height: fit-content;
  z-index: 30; 
  transition: transform 0.5s ease, top 0.5s ease; 
}

.reduced {
  transform: scale(0.7); 
  top: 40%; 
}

.normal {
  transform: scale(1);
  top: 20%; 
}


.logo {
  width: 90%; /* 26vw; /*500px;*/
  height: auto;
  margin: auto;
}

.buttons {

  display: flex;
  align-items: center; 
  justify-content: space-between; 
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 10px;
}

.play {
  width: fit-content;
  height: fit-content;
  margin-right: 10px; 
}

.info {
  width: fit-content;
  height: fit-content;
  margin-right: 10px; 
}


.icon_play {
  height:      50px;
}

.icon_play:hover {
  cursor: pointer;
}

.icon_info {
  height:      50px;
}

.icon_info:hover {
  cursor: pointer;
}


.text {
  color:       #FFFFFF;
  text-align:  justify;
 /* font-size:   20px;
  font-weight: bold; */
  text-wrap:   wrap;  
  width:       33vw; /*100%; /*650px;*/
  heigh:       fit-content;
  
  
  font-size: calc(1vw + 8px); /* Ajuste la taille du texte en fonction de la largeur de la fenêtre */
  font-weight: calc(800 - (2 * (100vw - 1920px) / 1920)); /* Réduit le poids à mesure que la fenêtre diminue */
  transition: font-size 0.3s ease, font-weight 0.3s ease; /* Ajoute une transition pour des changements plus fluides */


}

.text2 {
  color: #FFFFFF;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  margin-left: 1vw;
  margin-right: 1vw;
  width: fit-content;
}

.centeredText {
  margin-top: auto; 
  margin-bottom: auto;
}

/*
.preview {
  pointer-events: none; 
  z-index: 5;
  width:   100%;
 
}
*/

.preview {
  pointer-events: none;
  z-index: 5;
  width: 100%;
  position: relative;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20; /* Ensure it is above the image */
  pointer-events: auto; /* Permet aux éléments enfants d'être interactifs */
}

.videoPlayer {
  width: 100%;
  position: relative;
}

.sound {
  position: absolute;
  top: 55%; /* Position à 40% du haut */
  right: 5%; /* Position à 5% du bord droit */
  width: fit-content;
  height: fit-content;
  z-index: 50; /* Ensure it is above the image */
}

.icon_sound {
  height:      50px;
}

.icon_sound:hover {
  cursor: pointer;
}
