$page-background-color:             #000000;
$page-text-color:                   #000000;
$page-text-size-1:                  16px;
$page-text-size-2:                  12px;
$page-text-size-h1:                 20px;

$block-border-color:                #FFFFFF;
$block-background-color:            #000000;     /* linear-gradient(#333,#222); */
$block-link-color:                  #FFFFFF;
$block-text-color-1:                #FFFFFF;
$block-text-color-2:                #F0F0F0;
$block-text-size-1:                 16px;
$block-text-size-2:                 16px; 
$block-text-size-h1:                20px;

$menu-background-color:             #000000; 
$menu-text-color:                   #FFFFFF;
$menu-text-size-1:                  20px;
$menu-text-size-2:                  16px;
$menu-width-limit:                  965px !global;

$z-index-window-cookies-manager:    100;
$z-index-window-cookies-manager-2:  101;
$z-index-menu-header:               50;
$z-index-window-forgotten-password: 60;
$z-index-voile:                     55;


$max-width-4:                       430px;   /* Pour les smartphones */