@import '../../../config.module.scss';

.root {

  display: relative;
  
  padding-top: 30px;
  padding-left: 130px;
  padding-right: 130px;

  background-color: rgba(0, 0, 0, 1);

}

.footer {
    
  background-color: rgba(0, 0, 0, 1);
  width:            100%;
  height:           200px;
  
  display:               grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap:              0px;
  grid-template-rows:    auto;
}
 
.position_1_1 {
  grid-column:     1;
  grid-row:        1;
} 
 
.position_1_2 {
  grid-column:     2;
  grid-row:        1;
} 

.position_1_3 {
  grid-column:     3;
  grid-row:        1;
} 
.position_1_4 {
  grid-column:     4;
  grid-row:        1;
} 
.position_2_1 {
  grid-column:     1;
  grid-row:        2;
} 
.position_2_2 {
  grid-column:     2;
  grid-row:        2;
} 
.position_2_3 {
  grid-column:     3;
  grid-row:        2;
} 
.position_2_4 {
  grid-column:     4;
  grid-row:        2;
} 
 
.button{
  height:           fit-content;
  width:            fit-content;
  font-size:        $menu-text-size-2;
  color:            white;
  background-color: black;
  padding:          0 5px;
  margin-top:       5px;	
  margin-bottom:    5px;	
  margin-right:     8px;
  margin-left:      8px;
  border:           0px solid #FFFFFF;
  cursor:           pointer;
  letter-spacing:   1px;
}


.button:hover {
  color:            black;
  background-color: white;
}

@media only screen and (max-width: 1100px) {

.root {

  display: relative;
  
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;

  background-color: rgba(0, 0, 0, 1);

}

.footer {
    
  background-color: rgba(0, 0, 0, 1);
  width:            100%;
  height:           fit-content;
  
  display:               grid;
  grid-template-columns: 1fr ;
  grid-gap:              0px;
  grid-template-rows:    auto;
}
 
.position_1_1 {
  grid-column:     1;
  grid-row:        1;
} 
 
.position_1_2 {
  grid-column:     1;
  grid-row:        2;
} 

.position_1_3 {
  grid-column:     1;
  grid-row:        3;
} 
.position_1_4 {
  grid-column:     1;
  grid-row:        4;
} 
.position_2_1 {
  grid-column:     1;
  grid-row:        5;
} 
.position_2_2 {
  grid-column:     1;
  grid-row:        6;
} 
.position_2_3 {
  grid-column:     1;
  grid-row:        7;
} 
.position_2_4 {
  grid-column:     1;
  grid-row:        8;
} 
 

}