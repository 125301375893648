.root {



}

.contain {

    border-radius:         20px;
  border:          1px solid #FFFFFF;
  padding:         20px;

  display:               grid;
  grid-template-columns: auto;
  grid-gap:              0px;
  grid-template-rows:    auto;
  
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(10px); /* Effet de flou */

}

.top {

  grid-column:     1;
  grid-row:        1;
}

.middle {

  grid-column:     1;
  grid-row:        2;
}

.bottom {

  grid-column:     1;
  grid-row:        3;
}

.controls {

  display:               grid;
  grid-template-columns: 30% 40% 30%;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

.left {

  grid-column:     1;
  grid-row:        1;
}

.center {

  grid-column:     2;
  grid-row:        1;
}

.right {

  grid-column:     3;
  grid-row:        1;
}

.trigger_normal{
  cursor: pointer;
  outline: none;
  color: #FFFFFF;
  margin: 5px;
  padding: 5px;
  font-size:             16px;

  border-radius: 5px 5px 5px 5px;
  border: 1px solid #444;
  caret-color: #FFFF00;
  background: linear-gradient(#333,#222);
}

.trigger_normal:disabled{
  cursor: not-allowed;
  pointer-events: all !important;
}

.trigger_normal:hover{
  color: #FFFF00;
  border: 1px solid #FFFF00;
  box-shadow: 0 0 5px rgba(255,255,0,.3),
              0 0 10px rgba(255,255,0,.2),
              0 0 15px rgba(255,255,0,.1),
              0 2px 0 black;
}

.trigger_normal:disabled:hover{
  color: #FFFFFF;
  border: 1px solid #7F7F7F;
  box-shadow: 0 0 5px rgba(127,127,127,.3),
              0 0 10px rgba(127,127,127,.2),
              0 0 15px rgba(127,127,127,.1),
              0 2px 0 black;
}

.trigger_pushed{
  cursor: pointer;
  outline: none;
  color: #FFFFFF;
  margin: 5px;
  padding: 5px;
  font-size:             16px;

  border-radius: 5px 5px 5px 5px;
  border: 1px solid #CCC;
  caret-color: #FFFFFF;
  background: linear-gradient(#999,#666);

}

