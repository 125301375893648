.root {

  color:      #FFFFFF;
}

.link{
  color:      #FFFFFF;
}

.paragraph {
  color:      #FFFFFF;
  text-wrap:  wrap;
  text-align: justify;
}
