@import '../../../../../config.module.scss';

.root {
}

.title{
  color:                 $block-text-color-1;
  font-size:             $block-text-size-h1;
  text-align:            center;
  font-weight:           700;
}

.subtitle{
  color:                 $block-text-color-1;
  font-size:             $block-text-size-1;
  text-align:            left;
  font-weight:           700;
  margin-bottom:         30px;
}

.form {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: auto;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

.line_1 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        1;
}

.line_2 {
  width:           100%;
  display:         inline;
  grid-column:     1;
  grid-row:        2;
}

.line_3_a {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        3;
}

.line_3_b {
  width:           100%;
  display:         inline;
  grid-column:     1;
  grid-row:        3;
}

.line_4 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        4;
}

.line_5 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        5;
}

.line_6 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        6;
}

.line_7 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        7;
}

.line_8 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        8;
}

.line_9 {
  width:           100%;
  display:         flex;
  flex-direction: column;
  grid-column:     1;
  grid-row:        9;
}

.button {

  margin-left:  10px;
  margin-right:  10px;
  padding: 10px;
}

.button_validate {

  width: fit-content;
  margin:  auto;
  padding: 10px;
}

.buttons_payment {

  display: flex;
  margin:  auto;
  padding: 10px;
}

.link {

  color:                 $block-link-color;
  font-size:             $block-text-size-1;
}

.text_rgpd {

  color:                 $block-text-color-1;
  font-size:             $block-text-size-1;
  text-align:            justify;
}

.grid_rgpd
 {
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             $block-text-size-1;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.grid_info
 {
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             $block-text-size-1;
  
  display:               grid;
  grid-template-columns: 215px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.text_payment {

  color:                 $block-text-color-1;
  font-size:             $block-text-size-1;
  text-align:            center;
  width:                 100%;
  font-weight:           700;
}


.sectionName {

  text-align:            left;
  color: #FFFFFF;
  font-weigh: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}


@media only screen and (max-width: 870px) {

   .buttons_payment {
     display: inline; 
  }
}


