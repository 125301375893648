.root {
  display:       inline-block;
  font-size:     1em;
  width:         fit-content;
  height:        fit-content;

  position:      fixed;
  top:           50%;
  left:          50%;
  transform:     translate(-50%, -50%);

  margin:        auto;
  padding:       30px;
  overflow:      auto;
  z-index:       6000;
  border-radius: 10px;

  outline:       none;
  color:         #FFFFFF;
  border:        1px solid #FFFF00;
  caret-color:   #FF00FF;

  text-align:    center;
  
 /* background:            rgba(0, 0, 0, 0.5); */
  backdrop-filter:       blur(10px); /* Effet de flou */

  background-color: var(--default-opaque-bg-color, rgba(0, 0, 0, 0.5));
}

.buttons_block {
  margin: auto;
}

.grid {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: 20px auto;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

.grid_1_1 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        1;
}

.grid_1_2 {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        1;
}

.grid_2_1 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        2;
}

.grid_2_2 {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        2;
}

.grid_3 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        3;
}

.logo {

  height:   25px;
  width:    auto;
}

.title {
  font-size: 16px;
  color:     #FFFFFF;
  margin:    auto;
  margin-bottom: 10px;
}

.buttons {

  margin: auto;
  margin-top: 10px;
}