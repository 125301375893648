
.part2 {

  height: fit-content;
}


.contain {

  width: 100%;
}


.grid {

  height:                fit-content;
  
  pading:                0px;
  margin:                10px;
  margin-left:           200px;
  margin-right:          200px;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap:              10px;
  grid-template-rows:    auto;
}


.block_1_1, .block_1_2,
.block_2_1, .block_2_2,
.block_3_1, .block_3_2,
.block_4_1, .block_4_2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Vous pouvez garder les autres styles, comme display: flex et justify-content: flex-end, si nécessaire */
.block_2_2, .block_4_2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.inter_1,
.inter_2,
.inter_3,
.inter_4 {

  height: 100px;
}


.block_1_1 {
  grid-column:     1/6;
  grid-row:        1;
}

.block_1_2 {
  grid-column:     7/15;
  grid-row:        1;
  vertical-align: middle
}

.inter_1 {
  grid-column:     1/16;
  grid-row:        2;
}

.block_2_1 {
  grid-column:     3/11;
  grid-row:        3;

}

.block_2_2 {
  grid-column:     12/17;
  grid-row:        3;
  display: flex;
  justify-content: flex-end;
}

.inter_2 {
  grid-column:     1/16;
  grid-row:        4;
}

.block_3_1 {
  grid-column:     1/6;
  grid-row:        5;
}

.block_3_2 {
  grid-column:     7/15;
  grid-row:        5;
}

.inter_3 {
  grid-column:     1/16;
  grid-row:        6;
}

.block_4_1 {
  grid-column:     3/11;
  grid-row:        7;
}

.block_4_2 {
  grid-column:     12/17;
  grid-row:        7;
  display: flex;
  justify-content: flex-end;
}

.inter_4 {
  grid-column:     1/16;
  grid-row:        6;
}


@media only screen and (max-width: 1100px) {


.grid
 {
  height:                fit-content;
  width:                 90%;
 
  pading:                0px;
  margin:                10px;
  margin-left:           auto;
  margin-right:          auto;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 100%;
  grid-gap:              10px;
  grid-template-rows:    auto;
}


.block_1_1 {
  grid-column:     1;
  grid-row:        1;
}

.block_1_2 {
  grid-column:     1;
  grid-row:        2;
  vertical-align:  middle
}

.inter_1 {
  grid-column:     1;
  grid-row:        3;
}

.block_2_1 {
  grid-column:     1;
  grid-row:        4;

}

.block_2_2 {
  grid-column:     1;
  grid-row:        5;
  display:         flex;
  justify-content: flex-end;
}

.inter_2 {
  grid-column:     1;
  grid-row:        6;
}

.block_3_1 {
  grid-column:     1;
  grid-row:        7;
}

.block_3_2 {
  grid-column:     1;
  grid-row:        8;
}

.inter_3 {
  grid-column:     1;
  grid-row:        9;
}

.block_4_1 {
  grid-column:     1;
  grid-row:        10;
}

.block_4_2 {
  grid-column:     1;
  grid-row:        11;
  display: flex;
  justify-content: flex-end;
}
  
.inter_4 {
  grid-column:     1;
  grid-row:        9;
}

}



.text {

  color: #FFFFFF
}

.block_text {

 margin: 0px;
}


.bold_italic {

  font-weight: bold; 
   font-style: italic;

}

@media only screen and (max-width: 1100px) {

  .block_text {

    text-align: left;
  }
}


.image {

margin: auto;
width: fit-content;
}

table {
    width: 100%; /* Largeur du tableau */
    background-color: #000000; /* Couleur de fond pour voir les bordures blanches */
    border-collapse: collapse; /* Pour éviter l'espace entre les bordures */
  }
  th, td {
    border: 1px solid black; /* Bordures blanches */
    padding: 10px; /* Espacement intérieur */
  }

.btn {

 width: fit-content;
 margin: auto;
}




.boldText {

 font-weight: bold;

}

.unbold {

font-weight: normal;
 font-size: 14px;
 font-style: italic;
 color: #A1A1AA;

}


.Masterclass {
 color: #FFFFFF;
 font-size: 20px;
 font-weight: bold;
 font-style: italic;
 width: fit-content;
 margin: auto;
}

.centered_text {
 width: fit-content;
 margin:auto;
}



.shift {

  height: 80px;
}


.list {
   list-style-type: disc;
   padding-left: 20px;
}
