.root {
}

.title{
  color:       #FFFFFF;
  text-align:  center;
  font-weight: bold;
}

.informations{
  color: #FFFFFF;
  font-size: 16px;
}

.buttons {

  margin: auto;
  padding: 10px;
  width: fit-content;
}


@media only screen and (max-width: 870px) {

   .buttons {
     display: inline; 
  }
}


.sectionName {

  text-align:            left;
  color: #FFFFFF;
  font-weigh: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.choices_line {

  text-align:  left;
  margin: auto;
  padding: 10px;
}
