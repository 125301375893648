.root {

  color:           #FFFFFF;
}

.contain {
  height:          100%; 
  width:           100%;
  max-width:       800px; 
  margin:          auto;
  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
  color:           #FFFFFF;
}

.accordion {

  width:         100%;
  outline:       none;
  font-size:     16px;
  color:         #FFFFFF;
/*  border-radius: 5px;
  border:        2px solid #444;
  background:    linear-gradient(#333,#222); */
  margin:        0;
  padding:       10px;
  box-sizing:    border-box;  
}


.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
	  box-sizing:    border-box;  

}

.accordion__button {

  width:         100%;
  outline:       none;
  font-size:     16px;
  color:         #FFFFFF;
  border-radius: 5px;
  border:        2px solid #444;
  background:    linear-gradient(#333,#222);
  margin:        0;
  padding:       0 10px;
  box-sizing:    border-box;  
  cursor: pointer;
  box-sizing:    border-box;  
  
 text-wrap: wrap;
 text-align: justify;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
	  box-sizing:    border-box;  
	background: linear-gradient(#333,#222);

}
