@import '../../../config.module.scss';

.root {

  height:          78vh;  /*990px;  100vh; */
  width:           100%;

  background-image: url("https://lovelight.tv/images/fond.jpg");
  color:           #FFFFFF;
}

.contain {
  height:                fit-content; 
  width:                 fit-content;
  margin:                auto;
  
  position:              fixed;
  top:                   50%;
  left:                  50%;
  transform:             translate(-50%, -50%);
  
  justify-content:       center;
  align-items:           center; 
  align:                 center;
  place-items:           center;
  text-align:            center;

  border-radius:         10px;
  border:                1px solid #FFFFFF;
  padding:               10px;
  box-sizing:            border-box;

  background:            rgba(0, 0, 0, 0);
  backdrop-filter:       blur(10px); /* Effet de flou */
}

.message {
 width:    fit-content;
 margin:   auto;
}

.message2 {
  opacity: 0;
  color: #FFFFFF;
  
  animation: fadeIn 5s ease forwards; /* Animation d'apparition pendant 1 seconde */
  animation-delay: 3s; /* Délai de démarrage de l'animation */
}

.button{
  margin:  auto;
  padding: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}