@import '../../../config.module.scss';

.top_shifter_whith_header{
  
  height: 70px;
  background:      $page-background-color;

}

.top_shifter_whithout_header{
  
  height: 40px;
  background:      $page-background-color;

}
