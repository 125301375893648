@import '../../../config.module.scss';

.root {
  height:          100vh;  /* 78vh;  /*990px;  100vh; */
  width:           100%;

  background:      $page-background-color;
  color:           $page-text-color;

  background-image: url("https://lovelight.tv/images/fond.jpg");

  padding-top:      30px;


}

.contain {
  height:          fit-content; 
  width:           95%;
  max-width:       900px;
  margin:          auto;
  padding:         5px;
}
