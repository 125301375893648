.root {
  display:       inline-block;
  font-size:     1em;
  width:         800px;
  height:        fit-content;

  position:      fixed;
  top:           50%;
  left:          50%;
  transform:     translate(-50%, -50%);

  margin:        auto;
  padding:       30px;
  overflow:      auto;
  z-index:       16000;
  border-radius: 10px;

  outline:       none;
  color:         #FFFFFF;
  border:        1px solid #FFFF00;
  caret-color:   #FF00FF;

  text-align:    center;
  
 /* background:            rgba(0, 0, 0, 0.5); */
  backdrop-filter:       blur(10px); /* Effet de flou */

  background-color: var(--default-opaque-bg-color, rgba(0, 0, 0));
}


@media only screen and (max-width: 850px) {

  .root {

    position:      fixed;

    width:         90vw;
    height:        fit-content;
  
    left:          5vw;
    top:           100px;

    z-index:       16000;
  
    background:    #000000;
  
    overflow: auto;
  
    transform: none; /* Réinitialise la transformation */
  }
 
}

.grid_1 {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: 800px;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

@media only screen and (max-width: 850px) {

  .grid_1 {
    width:                 100%;
    pading:                0;
    margin:                0;
  
    display:               grid;
    grid-template-columns: 100%;
    grid-gap:              10px;
    grid-template-rows:    auto;
  }
}

.grid_1_line_1 {
  width:           100%;
  display:         inline;
  grid-column:     1;
  grid-row:        1;
  text-wrap: wrap;
  text-align: justify;
text-justify: inter-word;
}

.grid_1_line_2 {
  width:           100%;
  display:         inline;
  grid-column:     1;
  grid-row:        2;
  text-wrap: wrap;
  text-align: justify;
text-justify: inter-word;
}

.grid_1_line_3 {
  width:           100%;
  display:         inline;
  grid-column:     1;
  grid-row:        3;
  text-wrap: wrap;
  text-align: justify;
text-justify: inter-word;
}

.grid_1_line_4 {
  width:           100%;
  display:         inline;
  grid-column:     1;
  grid-row:        4;
  text-wrap: wrap;
  text-align: justify;
text-justify: inter-word;
}

.grid_1_line_5 {
  width:           100%;
  display:         inline;
  grid-column:     1;
  grid-row:        5;
  text-wrap: wrap;
  text-align: justify;
text-justify: inter-word;
}

.grid_1_line_6 {
  width:           100%;
  display:         inline;
  grid-column:     1;
  grid-row:        6;
  text-wrap: wrap;
  text-align: justify;
text-justify: inter-word;
}

.grid_1_line_7 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        7;
  text-wrap: wrap;
  text-align: justify;
text-justify: inter-word;
}

.buttons {

  margin: auto;
  margin-top: 10px;
}