.root {
  color:       #FFFFFF;
  padding: 10px;
}

.title{
  color:       #FFFFFF;
  text-align:  center;
  font-weight: bold;
}

.form {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: 50% auto;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

.layout_1_line_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        1;
}

.layout_1_line_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        2
}

.layout_1_line_3_left {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        3;
}

.layout_1_line_3_right {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        3;
}

.layout_1_line_4 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        4;
}

.layout_1_line_5 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        5;
}

.layout_1_line_6 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        6;
}

.layout_1_line_7_left {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        7;
}

.layout_1_line_7_right {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        7;
}

.layout_1_line_8_left {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        8;
}

.layout_1_line_8_right {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        8;
}

.layout_1_line_9 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        9;
}

.layout_1_line_10 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        10;
}

.layout_1_line_11 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        11;
}

.layout_1_line_12 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        12;
}

.layout_1_line_13 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        13;
}

.layout_1_line_14 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        14;
}

.layout_1_line_15 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        15;
}

.layout_1_line_16 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        16;
}

.layout_1_line_17 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        17;
}

.layout_1_line_18 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        18;
}

.layout_1_line_19 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        19;
}

.layout_1_line_20 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        20;
}

.layout_1_line_21 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        21;
}

.layout_2_line_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        1;
}

.layout_2_line_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        2;
}

.layout_2_line_3 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        3;
}

.layout_2_line_4 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        4;
}

.layout_2_line_5 { 
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        5;
}

.layout_2_line_6 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        6;
}

.layout_2_line_7 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        7;
}

.layout_2_line_8 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        8;
}

.layout_2_line_9 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        9;
}

.layout_2_line_10 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        10;
}

.layout_2_line_11 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        11;
}

.layout_2_line_12 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        12;
}

.layout_2_line_13 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        13;
}

.layout_2_line_14 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        14;
}

.layout_2_line_15 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        15;
}

.layout_2_line_16 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        16;
}

.layout_2_line_17 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        17;
}

.layout_2_line_18 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        18;
}

.layout_2_line_19 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        19;
}

.layout_2_line_20 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        20;
}

.layout_2_line_21 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        21;
}

.layout_2_line_22 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        22;
}

.layout_2_line_23 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        23;
}

.layout_2_line_24 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        24;
}

.layout_2_line_25 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        25;
}

.buttons {

  margin: auto;
}

.link {

  color: #FFFFFF;
}

.grid_info
 {
  width:                 100%;
  pading:                0px;
  margin:                0;
  font-size:             16px;
  text-align:            left;
  
  display:               grid;
  grid-template-columns: 170px auto;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

.buttons_block {
  margin: auto;
}



.grid_accompanyingPersonPresence{
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.grid_airTransport{
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.grid_baggageToBeCheckedIn{
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.grid_largeCarryOnBaggage{
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.grid_airportTransfer{
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.grid_carRental{
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.grid_contactRequest{
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.text_accompanyingPersonPresence {

  color: #FFFFFF;
}

.text_airTransport {

  color: #FFFFFF;
}

.text_baggageToBeCheckedIn {

  color: #FFFFFF;
}

.text_largeCarryOnBaggage {

  color: #FFFFFF;
}

.text_airportTransfer {

  color: #FFFFFF;
}

.text_carRental {

  color: #FFFFFF;
}


.btn {

 width: fit-content;
 margin: auto;
 margin-top: 10px;
}


