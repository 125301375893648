.root {
 
}

.contain {
  display:               grid;
  grid-template-columns: 150px auto;
  grid-gap:              0px;
  grid-template-rows:    auto;

}

.left {

  grid-column:    1;
  grid-row:       1;
}

.right {

  border-radius:  10px;
  border:         1px solid #FFFFFF;
  padding:        10px;
  box-sizing:     border-box;

  grid-column:    2;
  grid-row:       1;

  background:            #FFFFFF;
  
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(10px); /* Effet de flou */
  
  
}

.trigger_normal{
  width :         140px;
  cursor:         pointer;
  outline:        none;
  margin:         0px;
  margin-bottom:  5px;
  padding:        5px;
  font-size:      16px;
  box-sizing:     border-box;
  
    background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(10px); /* Effet de flou */
}

.trigger_normal:disabled{
  cursor:         not-allowed;
  pointer-events: all !important;
}

/*
.trigger_normal:hover{
  color:          #FFFF00;
  border:         1px solid #FFFF00;
  box-shadow:     0 0 5px rgba(255,255,0,.3),
                  0 0 10px rgba(255,255,0,.2),
                  0 0 15px rgba(255,255,0,.1),
                  0 2px 0 black;
				  
				  
}

.trigger_normal:disabled:hover{
  color:          #FFFFFF;
  border:         1px solid #7F7F7F;
  box-shadow:     0 0 5px rgba(127,127,127,.3),
                  0 0 10px rgba(127,127,127,.2),
                  0 0 15px rgba(127,127,127,.1),
                  0 2px 0 black;
}
*/

.trigger_pushed{
  width :         140px;
  cursor:         pointer;
  outline:        none;
  margin:         0px;
  margin-bottom:  5px;
  padding:        5px;
  font-size:      16px;
  box-sizing:     border-box;
  
    background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(10px); /* Effet de flou */
}

