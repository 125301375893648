@import '../../../config.module.scss';

.root {

}

.widget_frame
 {
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

/* 
   Layout 1

   +-------+-------+
   |       |       |
   +-------+-------+
   |               |
   +---------------+
*/

.widget_frame_layout_1_position_1_1 {
  grid-column:     1;
  grid-row:        1;
}

.widget_frame_layout_1_position_1_2 {
  grid-column:     2;
  grid-row:        1;
}

.widget_frame_layout_1_position_2 {
  grid-column:     1/3;
  grid-row:        2;
}

/* 
   Découpage 2

   +---------------+
   |               |
   +---------------+
   |               |
   +---------------+
   |               |
   +---------------+
*/

.widget_frame_layout_2_position_1 {
  grid-column:     1/3;
  grid-row:        1;
}

.widget_frame_layout_2_position_2 {
  grid-column:     1/3;
  grid-row:        2;
}

.widget_frame_layout_2_position_3 {
  grid-column:     1/3;
  grid-row:        3;
}



.widget_frame_name  {
  padding:         0;
  margin:          0;
}

.name_left  {
  place-items:     left;
  justify-content: left;
  text-align:      left;
  align-items:     left;
}

.name_right  {
  place-items:     right;
  justify-content: right;
  text-align:      right;
  align-items:     right;
}

.widget_frame_info {
  font-size:       80%;
  color:           #FF0000;
  padding:         0;
  margin:          0;
  place-items:     center;
  justify-content: center;
  text-align:      center;
  align-items:     center;
}

.widget_frame_element
 {
  color:           #FFFF00;
  width:           100%;
  padding:         0;
  margin:          0;
  box-sizing:      border-box;
}

.widget_frame_required {
  font-size:       0.8em;  
  vertical-align:  super;  
  color:           #FF0000;
}
