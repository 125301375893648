
.root {
/*  font-family: "Ribeye Marrow", "Shadows Into Light", cursive; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 140px;
  font-size: 232px; /*calc(100% - 10px); /* Calcule la taille en fonction du conteneur parent avec une marge */
  margin: 0;
  padding: 0;
  overflow: hidden; /* prevent overflow in case the text is too large */
  color: #FFFFFF;
  font-weight: bold;
}

.root2 {
/*  font-family: "Ribeye Marrow", "Shadows Into Light", cursive; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 140px;
  font-size: 150px; /*calc(100% - 10px); /* Calcule la taille en fonction du conteneur parent avec une marge */
  margin: 0;
  padding: 0;
  overflow: hidden; /* prevent overflow in case the text is too large */
  color: #FFFFFF;
  font-weight: bold;
}
