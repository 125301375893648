.root {
}

.date_selector
 {
  width:           100%;
  padding:         0;
  margin:          0;
  padding:         0px;
  box-sizing:      border-box;
  
  display:               grid;
  grid-template-columns: auto 5px auto 5px auto;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

.day_block
 {
  grid-column: 1;
  grid-row:    1;
}

.month_block
 {
  grid-column: 3;
  grid-row:    1;
}

.year_block
 {
  grid-column: 5;
  grid-row:    1;
}
