@charset "UTF-8";
.szh-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  width: max-content;
  z-index: 100;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #000000;
  color:  #0000FF;
}
.szh-menu:focus {
  outline: none;
}
.szh-menu__arrow {
  box-sizing: border-box;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #fff;
  border: 1px solid transparent;
  border-left-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.1);
  z-index: -1;
}
.szh-menu__arrow--dir-left {
  right: -0.375rem;
  transform: translateY(-50%) rotate(135deg);
}
.szh-menu__arrow--dir-right {
  left: -0.375rem;
  transform: translateY(-50%) rotate(-45deg);
}
.szh-menu__arrow--dir-top {
  bottom: -0.375rem;
  transform: translateX(-50%) rotate(-135deg);
}
.szh-menu__arrow--dir-bottom {
  top: -0.375rem;
  transform: translateX(-50%) rotate(45deg);
}
.szh-menu__item {
  cursor: pointer;
  color: #FFFFFF;
  background-color: #000000;
}
.szh-menu__item:focus {
  outline: none;
  color: #000000;
  background-color: #FFFFFF;
}

.szh-menu__item--hover {
  color: #000000;
  background-color: #FFFFFF;
}
.szh-menu__item--focusable {
  cursor: default;
  background-color: inherit;
}
.szh-menu__item--disabled {
  cursor: default;
  color: #aaa;
}
.szh-menu__group {
  box-sizing: border-box;
}
.szh-menu__radio-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.szh-menu__divider {
  height: 1px;
  margin: 0.5rem 0;
  background-color: rgba(0, 0, 0, 0.12);
}

.szh-menu-button {
  box-sizing: border-box;
}

.szh-menu {
  user-select: none;
  color: #212529;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.133), 0 0.6px 2px rgba(0, 0, 0, 0.1);
  min-width: 10rem;
  padding: 0.5rem 0;
}
.szh-menu__item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.375rem 1.5rem;
}
.szh-menu-container--itemTransition .szh-menu__item {
  transition-property: background-color, color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.szh-menu__item--type-radio {
  padding-left: 2.2rem;
}
.szh-menu__item--type-radio::before {
  content: "○";
  position: absolute;
  left: 0.8rem;
  top: 0.55rem;
  font-size: 0.8rem;
}
.szh-menu__item--type-radio.szh-menu__item--checked::before {
  content: "●";
}
.szh-menu__item--type-checkbox {
  padding-left: 2.2rem;
}
.szh-menu__item--type-checkbox::before {
  position: absolute;
  left: 0.8rem;
}
.szh-menu__item--type-checkbox.szh-menu__item--checked::before {
  content: "✔";
}
.szh-menu__submenu > .szh-menu__item {
  padding-right: 2.5rem;
}
.szh-menu__submenu > .szh-menu__item::after {
  content: "❯";
  position: absolute;
  right: 1rem;
}
.szh-menu__header {
  color: #888;
  font-size: 0.8rem;
  padding: 0.2rem 1.5rem;
  text-transform: uppercase;
}

@keyframes szh-menu-show-slide-left {
  from {
    opacity: 0;
    transform: translateX(0.75rem);
  }
}
@keyframes szh-menu-hide-slide-left {
  to {
    opacity: 0;
    transform: translateX(0.75rem);
  }
}
@keyframes szh-menu-show-slide-right {
  from {
    opacity: 0;
    transform: translateX(-0.75rem);
  }
}
@keyframes szh-menu-hide-slide-right {
  to {
    opacity: 0;
    transform: translateX(-0.75rem);
  }
}
@keyframes szh-menu-show-slide-top {
  from {
    opacity: 0;
    transform: translateY(0.75rem);
  }
}
@keyframes szh-menu-hide-slide-top {
  to {
    opacity: 0;
    transform: translateY(0.75rem);
  }
}
@keyframes szh-menu-show-slide-bottom {
  from {
    opacity: 0;
    transform: translateY(-0.75rem);
  }
}
@keyframes szh-menu-hide-slide-bottom {
  to {
    opacity: 0;
    transform: translateY(-0.75rem);
  }
}
.szh-menu--state-opening.szh-menu--dir-left {
  animation: szh-menu-show-slide-left 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-left {
  animation: szh-menu-hide-slide-left 0.15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-right {
  animation: szh-menu-show-slide-right 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-right {
  animation: szh-menu-hide-slide-right 0.15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-top {
  animation: szh-menu-show-slide-top 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-top {
  animation: szh-menu-hide-slide-top 0.15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-bottom {
  animation: szh-menu-show-slide-bottom 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-bottom {
  animation: szh-menu-hide-slide-bottom 0.15s ease-in forwards;
}
