@import './config.module.scss';

@font-face {
  font-family: 'Newspaper';
  src: url('https://custom-front.preprod.lovelightfactory.fr/fonts/Newspaper.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'EuroStyle';
  src: url('https://custom-front.preprod.lovelightfactory.fr/fonts/EuroStyle_Normal.ttf') format('truetype'); /* Chemin vers votre fichier TTF */
}

@import url('https://fonts.googleapis.com/css?family=Barlow%20Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ribeye+Marrow&family=Shadows+Into+Light&display=swap');


*{
  margin:      0;
  padding:     0;
  font-family: 'Barlow Condensed', sans-serif;
  color:       $text-color-1;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  line-height:              1.5;
  font-weight:              400;

  color-scheme:             light dark;
  color:                    $text-color-1;

  font-synthesis:           none;
  text-rendering:           optimizeLegibility;
  -webkit-font-smoothing:   antialiased;
  -moz-osx-font-smoothing:  grayscale;
  -webkit-text-size-adjust: 100%;
  
  background: #000000;
  
  
}

body {
  margin:     0;
  background: #000000;
}
