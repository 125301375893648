.root {
  color: #FFFFFF;
}

.title{
  color:       #FFFFFF;
  text-align:  center;
  font-weight: bold;
}

.title_2{
  color:       #00FF00;
  text-align:  center;
  font-weight: bold;
}

.informations {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: auto 350px;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

/* 
   Layout 1

   +-------+-------+
   |       |       |
   +-------+-------+
   |       |       |
   +-------+-------+
*/

.layout_1_line_1_1 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        1;
}

.layout_1_line_1_2 {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        1;
}

.layout_1_line_2_1 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        2;
}

.layout_1_line_2_2 {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        2;
}

/* 
   Découpage 2

   +---------------+
   |               |
   +---------------+
   |               |
   +---------------+
   |               |
   +---------------+
   |               |
   +---------------+
*/

.layout_2_line_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        1;
}

.layout_2_line_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        2;
}

.layout_2_line_3 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        3;
}

.layout_2_line_4 {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        4;
}



.button {

  margin: auto;
  padding: 10px;
}

.grid_info
 {
  color:                 #FFFFFF;
 
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  text-align:            left;
  
  display:               grid;
  grid-template-columns: 120px auto;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.yellow_text {
  color: #FFFF00;
}

.blank_text {
  color: #FFFFFF;
}

.centered {
  width: fit-content;
  margin: auto;
}

.buttons_block {
  margin: auto;
}
