@import '../../../config.module.scss';

.root {
}

.header {
  width:                 100%;
  height:                70px;

  position:              fixed;
  top:                   0;
  left:                  0;
  z-index:               8000;
  
  text-align:            center;
  place-items:           center;
  justify-content:       center;
  vertical-align:        middle;
  
  background-color:      $menu-background-color;

  
  display:               grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows:    70px;
  box-sizing:            border-box;
 }

@media only screen and (max-width: 630px) {

  .header {

    grid-template-columns: 36vw 44vw 18vw;
  }
}
 
.left {
  grid-column:           1;
  grid-row:              1;
  text-align:            left;
  place-items:           left;
}

.center {
  grid-column:           2;
  grid-row:              1;
  text-align:            center;
  place-items:           center;
  font-size:             $menu-text-size-2;
}

.right {
  grid-column:           3;
  grid-row:              1;
  text-align:            right;
  place-items:           right;
  align:                 right;
}

@media only screen and (max-width: 630px) {

  .center {

      font-size:            14px;
  }
}

.block_buttons {
  width:                 fit-content;
  padding-left:          50px;
  margin-left:           0px;
  margin-right:          auto;
}

@media only screen and (max-width: 630px) {

  .block_buttons { 
  padding: 0px;
  margin:  auto;
  }
}

@media only screen and (max-width: 630px) {

  .block_buttons { 
     padding-left:  0px;
  }
}


 
.button {
  height:                30px;
  width:                 fit-content;
  
  color:                 $menu-text-color;
  background-color:      $menu-background-color;
  
  padding:               0 5px;
  margin-top:            5px;	
  margin-right:          5px;
  margin-left:           5px;
  border:                0px;
  
  cursor:                pointer; 
  
  font-size:             $menu-text-size-2;

  letter-spacing:        1px;
  font-weight:           600;
  text-align:            justify;
}

.button:hover {
  color:                 $menu-background-color;
  background-color:      $menu-text-color;
}

@media only screen and (max-width: 630px) {

  .button { 
    letter-spacing:        0px;
    font-weight:           normal;
    padding:   0 2px;
    font-size: 10px;
  }
}


.logo{
  padding-left:          50px;
  margin-left:	         4px;
  height:		         64px;
  width:                 auto;
  cursor:                pointer;
}

@media only screen and (max-width: 630px) {

  .logo { 
     padding-left:  0px;
     height:		45px;
  }
}

.avatar{
     margin-top:	         6px;
     margin-right:	         6px;
  height:		         32px;
  width:                 auto;
}

@media only screen and (max-width: 630px) {

  .avatar { 
     margin-top:	         6px;
     margin-right:	         6px;
     height:		22px;
  }
}


.button_yellow {
  height:                30px;
  width:                 fit-content;
  
    color:                 #FFFF00;
  background-color:      #000000;
  border:                1px solid #FFFF00;
  
  
  padding:               0 5px;
  margin-top:            5px;	
  margin-right:          5px;
  margin-left:           5px;
  border:                0px;
  
  cursor:                pointer; 
  
  font-size:             $menu-text-size-2;

  letter-spacing:        1px;
  font-weight:           600;
  text-align:            justify;
}

.button_yellow_2 {
  height:                30px;
  width:                 fit-content;
  
    color:                 #FFFF00;
  background-color:      #000000;
  border:                1px solid #FFFF00;
  
  
  padding:               0 5px;
  margin-top:            5px;	
  margin-right:          5px;
  margin-left:           5px;
  border:                0px;
  
  cursor:                pointer; 
  
  font-size:             $menu-text-size-2;

  letter-spacing:        1px;
  font-weight:           600;
  text-align:            justify;
}


@media only screen and (max-width: 630px) {

  .button_yellow {
    letter-spacing:        0px;
    font-weight:           normal;
    padding:   0 2px;
    font-size: 10px;
  }

  .button_yellow_2 {
    letter-spacing:        0px;
    font-weight:           normal;
    padding:   0 2px;
    font-size: 10px;
  }

}

.button_yellow:hover {
  color:                 #000000;
  background-color:      #FFFF00;

}

.submenu {
  position:         absolute;
  top:              70px;
  right:            5px;
  width:            fit-content;
  height:           fit-content; 
  padding:          10px;
  background-color: #000000;
  align-items:      center; 
  
  border:           1px solid #FFFFFF;
  border-radius:    10px;
}


.button_pushed {
  color:            #FFFF00;
    font-size: 16px;

}

@media only screen and (max-width: 630px) {

.button_pushed {
    font-size: 14px;

}
}


.cartContainer {
    position: relative;
    display: inline-block;
}

.cart {
    font-size: 18px;
}

.notification {
    position: absolute;
    top: -8px; /* Ajustez cette valeur pour affiner la position verticale */
    right: -8px; /* Positionne le badge à droite du symbole du panier */
    
    min-width: 18px; /* Définit la largeur minimale pour un seul chiffre */
    height: 18px; /* Hauteur fixe pour le badge */
    
    background-color: #FFFF00;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 0 4px; /* Ajoute du padding horizontal pour s'adapter aux nombres à 2 chiffres */
    border-radius: 50%;
    
    font-size: var(--gl-body-font-set-size-functional-3);
    font-weight: var(--gl-body-font-set-weight-functional-3);
    color: #000;
    
    -webkit-animation: _jiggle_kwit6_1 3s cubic-bezier(.25,.1,.25,1) infinite;
    animation: _jiggle_kwit6_1 3s cubic-bezier(.25,.1,.25,1) infinite;
    white-space: nowrap; /* Empêche le texte de se répartir sur plusieurs lignes */
}

@keyframes _jiggle_kwit6_1 {
    0%,9%,18% {
        transform: translateY(0)
    }

    2% {
        transform: translateY(-10px)
    }

    3% {
        transform: translateY(-3px)
    }

    5% {
        transform: translateY(-8px)
    }

    7% {
        transform: translateY(-2px)
    }

    11% {
        transform: translateY(-10px)
    }

    12% {
        transform: translateY(-3px)
    }

    14% {
        transform: translateY(-8px)
    }

    16% {
        transform: translateY(-2px)
    }
}