@import '../../../config.module.scss';

.root {
  height:          100vh; 
  width:           100%;

  background:      #000000;
  color:           #FFFFFF;
}

.contain {
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  
  height:           fit-content; 
  width:            fit-content;
  
  position:         fixed;
  top:              50%;
  left:             50%;
  transform:        translate(-50%, -50%);
}

.logo {
  margin:      20px;
  width:       380px;
}

.text {
  text-align:  center;
  font-size:   24px;
}