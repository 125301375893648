.root {
  
  position: fixed;
  top:      10vh;
  right:    87px;
  width:    696px;
  height:   80vh;
  z-index:  109;
  color:    #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 20px;
  
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px); /* Effet de flou */

  overflow: hidden;

}

@media only screen and (max-width: 870px) {

.root {
  left:     auto;
  right:    10vw;
  width:    80vw;
 }
}

.thumbnail {

  height:auto;
  width: 100%;
}



.icon {

  position: absolute;
  top:      10px;
  right:    10px;
}

.icon:hover {

  cursor: pointer;
}

.title {

  font-size: 24px;
  font-weight: bold;
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 10px;
  color: #FFFFFF;
}


.informations {
  color: #FFFFFF;
  font-size: 16px;
}

.playlist_block {

  position: absolute;
  bottom:      0px;
 left: 50%;
  transform: translateX(-50%);
  height: 90%; /* Limite la hauteur de la liste de lecture à 50% de la hauteur du conteneur englobant */
  width: 100%;
  overflow-y: scroll; /* Utilisez 'scroll' si vous voulez forcer l'affichage de la barre de défilement */
}

.video_list {
}

.video_row {
  padding:               20px;
  display:               grid;
  grid-template-columns: auto;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.infos {
 color: #FFFFFF;
}

.video_date {
  font-size: 24px;
  font-weight: bold;
  grid-column:     1;
  grid-row:        1;
}

.video_title {
  font-size: 24px;
  font-weight: normal;
  grid-column:     1;
  grid-row:        2;
}

.video_image {
  grid-column:     1;
  grid-row:        3;
}



@media only screen and (max-width: 870px) {

.video_row {
  padding:               15px;
  display:               grid;
  grid-template-columns: auto;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.video_date {
  font-size: 24px;
  grid-column:     1;
  grid-row:        1;
}

.video_title {
  font-size: 24px;
  grid-column:     1;
  grid-row:        2;
}

.video_image {
  grid-column:     1;
  grid-row:        3;
  padding-bottom:  15px;
}

.title {

  font-size: 24px;
}

}


.day {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  background: #FFFF00;
  text-align: center;
}