.root {
   z-index: 10000;
   
  position:      fixed;
  top:           50%;
  left:          50%;
  transform:     translate(-50%, -50%);
}

@keyframes rotation {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.loader {
    width: 30px;
    height: 30px;
    animation: rotation 1s linear infinite; /* Animation de rotation */
}