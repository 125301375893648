@import '../../../config.module.scss';

.root {
  height:                100vh; 
  width:                 100%;

  background:            $page-background-color;
  color:                 $page-text-color;
  
  background-image:      url("https://lovelight.tv/images/fond.jpg");
}


.contain {

  border-radius:         20px;
  border:                1px solid #FFFFFF;
  padding:               30px;

  background:            rgba(0, 0, 0, 0.7);
  backdrop-filter:       blur(2px);
  
  height:                fit-content; 
  width:                 90%;
  max-width:             400px; 
  margin:                auto;
  
  position:              fixed;
  top:                   50%;
  left:                  50%;
  transform:             translate(-50%, -50%);
  
  justify-content:       center;
  align-items:           center; 
  align:                 center;
  place-items:           center;
  text-align:            center;
}

.title{
  color:                 #FFFFFF;
  font-size:             $page-text-size-h1;
  height:                fit-content; 
  width:                 fit-content; 
  margin:                auto;
  font-weight:           700;
}

.button{
  margin:                auto;
  padding:               10px;
}

.message_ack{
  color:#00FF00;
  text-align:    center;
}

.message_error{
  color:#FF0000;
  text-align:    center;
}

.grid_info
 {
  width:                 100%;
  pading:                0px;
  margin:                0;
  font-size:             16px;
  text-align:            left;
  
  display:               grid;
  grid-template-columns: 170px auto;
  grid-gap:              0px;
  grid-template-rows:    auto;
}


.text {
  color:         $block-text-color-1;
  font-size:     $block-text-size-1;
}
