@import '../../../config.module.scss';

.root {
  min-height:          78vh;  /*990px;  100vh; */
  height:          fit-content; 
  width:           100%;

  background-image: url("https://lovelightfactory.fr/images/fond.jpg");
  color:           #FFFFFF;
}

.shift {

  height:          70px; 
  width:           95%;
}

.contain {
  height:          fit-content; 
  width:           95%;
  max-width:       600px;
  margin:          auto;
  padding:         20px;
  
  border-radius:   5px;
  border:          1px solid #FFFFFF;
  
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(10px); /* Effet de flou */
}
