@import '../../../config.module.scss';

.root {

  margin:           auto;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  
  height:           fit-content; 
  width:            100%;
  
  background:       #141414;
}


.alert {

  color : #FF0000;
  font-size: 16px;
}