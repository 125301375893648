.carouselContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transition: padding 0.3s ease;
}

@media (max-width: 500px) {
  .carouselContainer {
    padding: 2vw; /* Diminue progressivement le padding en dessous de 500px */
  }
}

.carouselWrapper {
  width: calc(100% - 60px); /* Largeur initiale en tenant compte des boutons (~40px de chaque côté) et du padding */
  overflow: hidden;
}

@media (max-width: 500px) {
  .carouselWrapper {
    width: calc(100% - 12vw); /* Ajuste la largeur en fonction du changement de padding et de bouton */
  }
}

.carouselContent {
  display: flex;
  transition: transform 0.5s ease;
}

.carouselItem {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselBtn {
  border: none;
  cursor: pointer;
  font-size: 18px;
  transition: padding 0.3s ease, margin 0.3s ease;
}

.carouselBtn:focus {
  outline: none;
}

/*
@media (max-width: 500px) {
  .carouselBtn {
    padding: 2vw; 
    margin: 1vw;   
  }
}
*/