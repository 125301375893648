.root {
  display: flex;
  justify-content: center;
  align-items: center;

  background:       #141414;
  widht: 100%;
  height: fit-content;
    box-sizing: border-box; /* Include padding and border in element's total width and height */

}

.hiddenDiv {
  display: none;
}

.grid {
  width:                 100%;
  height:          fit-content;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: auto auto;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

.block_1_1 {
  width:           100%;
  height:          fit-content;
  display:         flex;
  grid-column:     1;
  grid-row:        1;
}

.block_2_1 {
  width:           100%;
  height:          fit-content;
  display:         flex;
  grid-column:     2;
  grid-row:        1;
}

.block_2 {
  width:           100%;
  height:          fit-content;
  display:         flex;
  grid-column:     1/3;
  grid-row:        2;
}

.sub_grid {
  width:                 100%;
  height:          fit-content;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: auto;
  grid-gap:              10px;
  grid-template-rows:    auto auto;
}

.line_1 {
  width:           100%;
  height:          fit-content;
  display:         flex;
  grid-column:     1;
  grid-row:        1;
}

.line_2 {
  width:           100%;
  height:          fit-content;
  display:         flex;
  grid-column:     1;
  grid-row:        2;
}



.category_title {
 color: #FFFFFF;
 margin: auto;
 margin-top: 10px;
 margin-bottom: 10px;
 font-size: 30px;
 text-align: center;
 font-weight: bold;
}


.category_products {
  display: flex;
  flex-wrap: wrap; /* Allow thumbnails to wrap to the next line if needed */
  justify-content: center; /* Center the thumbnails within the container */ 
}

.offers {

  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.grp {

  margin: 20px 0; /* Add some space between groups */
}



.line_2 {
  gap: 10px; /* Add some space between thumbnails */
}
