.root {
}

.textarea_input
 {
  width:           100%;
  min-height:      120px;
  padding:         0;
  margin:          0;
  padding-left:    10px;
  padding-right:   10px;
  box-sizing:      border-box;
  color:           #000000;
  border-radius:   5px;
  border:          2px solid #DDDDDD;
  caret-color:     #000000;
  font-size:       16px;
  background:      #DDDDDD; 
}

.textarea_input:focus
 {
  color:      #000000;
  box-shadow:       0 0 5px rgba(128,0,128,.2),
              inset 0 0 5px rgba(88,26,129,.1);
  animation:  glow .8s ease-out infinite alternate;  // On utilise l'animation décrite ci-dessous
}

.textarea_input:read-only
 {
  border:          2px solid #242424;
  background:      #f0f0f0;
}

@keyframes glow
 {
  0% {
        border-color: #581A81;
        box-shadow:         0 0 5px rgba(88,26,129,.2),
                      inset 0 0 5px rgba(0,0,0,.1);
       }
  100% {
        border-color: #581A81;
        box-shadow:         0 0 20px rgba(88,26,129,.6),
                      inset 0 0 10px rgba(88,26,129,.4);
       }
}
