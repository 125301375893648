@import '../../../config.module.scss';

.root {
  height:           fit-content; /*78vh;  /*990px;  100vh; */;
  width:            100%;

  background:       $page-background-color;
  color:            $page-text-color;

  background-image: url("https://lovelight.tv/images/fond.jpg");
  z-index:          4000;

  position:         relative;
  padding-bottom:   10px;
}

@media only screen and (max-width: 870px) {

.root {
  height: fit-content; 
}
}

.contain {
  height:                fit-content; 
  width:                 100%;
  max-width:             1200px;
  margin:                auto;
  padding-left:          20px;
  padding-right:         20px;
  
  justify-content:       center;
  align-items:           center; 
  align:                 center;
  place-items:           center;
  text-align:            center;
  box-sizing:            border-box;
}

.trigger_normal {

  color:                 #FFFFFF;
  border:                0px solid #FFFFFF;
  background:            #FFFFFF;
}

.trigger_normal:hover {
  color:                 #FFFF00;
  border:                0px solid #FFFFFF;
  /*
  box-shadow:            0 0 5px rgba(0,0,0,.3),
                         0 0 10px rgba(0,0,0,.2),
                         0 0 15px rgba(0,0,0,.1),
                         0 2px 0 black;*/
}

.trigger_pushed {

  color:                 #FFFF00; 
  border:                0px solid #FFFF00;
  background:            #DDDDDD;
}

.frame {

  margin-top: 10px;
  padding:  10px;
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(10px); /* Effet de flou */
}

@media (max-width: 400px) {

.contain {
  width:                 fit-content;
  margin:                auto;
  padding-left:          0px;
  padding-right:         0px;
  
  justify-content:       center;
  align-items:           center; 
  align:                 center;
  place-items:           center;
  text-align:            center;
  box-sizing:            border-box;
}

  .frame {
    width:    97vw;
    padding:  0;
  }
}
