.root {

  background:            #FFFFFF;
  
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(10px); /* Effet de flou */
}

.title {
  color:       #FFFFFF;
  text-align:  center;
  font-weight: bold;
}

.informations {
  color: #FFFFFF;
  font-size: 16px;
}

.payments_list {
}

.payments_row {
  display:               grid;
  grid-template-columns: 180px 240px 90px 270px auto;
  grid-gap:              2px;
  grid-template-rows:    auto;
}

.payments_date {
  grid-column:     1;
  grid-row:        1;
}

.payments_description {
  grid-column:     2;
  grid-row:        1;
  text-align:  left;

}

.payments_amount {
  grid-column:     3;
  grid-row:        1;
  text-align:  right;

}

.payments_means_of_payment {
  grid-column:     4;
  grid-row:        1;
  text-align:  left;
}
		   
.payments_reference_number {
  grid-column:     5;
  grid-row:        1;
  text-align:  left;
}			   

@media only screen and (max-width: 630px) {

.payments_row {
  display:               grid;
  grid-template-columns: 30px auto;
  grid-gap:              2px;
  grid-template-rows:    auto;
}

.payments_date {
  grid-column:     1/3;
  grid-row:        1;
  text-align:  left;
  padding-left: 5px;
  
}

.payments_description {
  grid-column:     2;
  grid-row:        2;
  text-align:  left;

}

.payments_amount {
  grid-column:     2;
  grid-row:        3;
  text-align:  left;

}

.payments_means_of_payment {
  grid-column:     2;
  grid-row:        4;
  text-align:  left;
}
		   
.payments_reference_number {
  grid-column:     2;
  grid-row:        5;
  text-align:  left;
  padding-bottom: 10px;
}			   
}





.ok {
 color: #00A000;
}

.error {
 color: #FF0000;
}

.infos {
 color: #FFFFFF;
}
