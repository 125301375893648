.root {
  color: #FFFFFF;
}

.title{
  color:       #FFFFFF;
  text-align:  center;
  font-weight: bold;
}

.form {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: auto;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

.layout_1_line_1 {
  width:           100%;
  /*display:         flex;*/
  grid-column:     1;
  grid-row:        1;
}

.layout_1_line_2 {
  width:           100%;
  grid-column:     1;
  grid-row:        2;
}

.layout_1_line_3 {
  width:           100%;
  grid-column:     1;
  grid-row:        3;
}

.layout_1_line_4 {
  width:           100%;
  grid-column:     1;
  grid-row:        4;
}


.questions_grid {
  display: grid;
  grid-template-columns: 180px auto 100px  100px ;
  gap: 0px;
  width: 100%;
  margin-left: 10px;
  margin-left: 10px;
}

.questions_grid_col_1 {
  display:         inline;
  padding:         3px;
  grid-column:     1;
  grid-row:        1;
  text-align:      center;
  font-size:       16px;  
  color:           #FFFFFF;
}

.questions_grid_col_2 {
  display:         inline;
  padding:         3px;
  grid-column:     2;
  grid-row:        1;
  font-size:       16px;  
  color:           #FFFFFF;
}

.questions_grid_col_3 {
  display:         inline;
  padding:         3px;
  grid-column:     3;
  grid-row:        1;
  text-align:      center;
  font-size:       16px;  
  color:           #FFFFFF;
}

.questions_grid_col_4 {
  display:         inline;
  padding:         3px;
  grid-column:     4;
  grid-row:        1;
  text-align:       center;
}

.standard {

  font-size: 16px;
  text-align: justify;
  text-wrap: wrap;
}

.questionTitle {

  font-size: 16px;
  text-align: justify;
  white-space:     nowrap; 
  overflow:        hidden; 
  text-overflow:   ellipsis; 
}

.columnTitle {

  font-size: 16px;
  font-weight: bold;
  text-align: center;
}


.text_consent {

  color:                 #FFFFFF;
  font-size:             16px;
  text-align:            justify;
}

.grid_consent
 {
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.noQuestionMessage {

  margin: auto;
  width: fit-content;
  font-size: 16px;
}
