.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 100%; /* Hauteur totale du conteneur parent */
  overflow: hidden; /* Empêcher tout débordement */
}

.image {
  height: 100%; /* L'image occupe toute la hauteur du conteneur */
  width: auto; /* La largeur est proportionnelle à l'image */
  position: relative;
  left: -50px; /* Décalage vers la gauche pour recouvrir environ 10 % du texte */
  z-index: 1; /* Place l'image au-dessus du chiffre */
  max-width: none !important; /* Force la désactivation de max-width */

}

/*
@media only screen and (max-width: 567px) {

  .image {

    left: 0px; 
  }
}
 */
