.root {

}

.block_buttons {

  display: flex;  
  justify-content: center; /* Aligne les éléments au centre horizontalement */
}

.button {

  margin: auto;
  padding: 10px;
}
