.root {
}

.field {
  width:           100%;
  display:         flex;
  padding:         0;
  margin:          0;
  box-sizing:      border-box;
}

.field_input
 {
  width:           100%;
  height:          28px;
  display:         flex;
  flex:            1;
  padding:         0;
  padding-left:    10px;
  padding-right:   10px;
  box-sizing:      border-box;
  color:           #000000;
  border-radius:   5px;
  border:          2px solid #DDDDDD;
  caret-color:     #000000;
  font-size:       16px;
  background:      #DDDDDD; 
}

.field_input[type="date"]::-webkit-calendar-picker-indicator {

  background-color: #FFFFFFFF;
  color: #000000FF;
  filter: invert(0);
}

.field_input:read-only
 {
  border:          0px;
  background:      #00000000;
}

.field_input:focus
 {
  box-shadow:       0 0 5px rgba(255,255,0,.2),
              inset 0 0 5px rgba(255,255,255,.1);
  animation:  glow .8s ease-out infinite alternate;  // On utilise l'animation décrite ci-dessous
}

@keyframes glow
 {
  0% {
        border-color: #FFFF00;
        box-shadow:         0 0 5px rgba(255,255,0,.2),
                      inset 0 0 5px rgba(255,255,255,.1);
       }
  100% {
        border-color: #FFFF00;
        box-shadow:         0 0 20px rgba(255,255,255,.6),
                      inset 0 0 10px rgba(255,255,0,.4);
       }
}

.span_eye {
  color:           #000000;
  width:           30px;
  align-items:     center;
  justify-content: center;
  cursor:          pointer;
 }

.slash{
  text-decoration: line-through;
}
