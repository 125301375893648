@import '../../../config.module.scss';

.choices {
  width:                 fit-content;
  pading:                0;
  margin:                auto;

  display:               grid;
  grid-template-columns: auto 160px 160px 160px;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.choices_v2 {
  width:                 371px;
  pading:                0;
  margin:                auto;
}


.button {
  width: 100%;
  height: 100%;
  background-color: #333333;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px; /* Ajustez la taille de la police si nécessaire */
  cursor: pointer;
}

.button:focus {
  outline: none; /* Retire le contour bleu par défaut du bouton au focus */
}

.features_column {
  width:           100%;
  grid-column:     1;
  grid-row:        1;

  pading:                0;
  margin:                0;

  display:               grid;
  grid-template-columns: auto;
  grid-gap:              5px;
  grid-template-rows:    81px auto 50px;

/* New properties */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.top_blank {
  width:           100%;
  grid-column:     1;
  grid-row:        1;
}

.choices_features_grid {

  width:                 100%;
  grid-column:     1;
  grid-row:        2;

  pading:                0;
  margin:                0;

  display:               grid;
  grid-gap:              0px;
  grid-template-columns: auto;
  grid-template-rows:    repeat(6, 24px);
}

.choices_features_grid_row {
  color: #FFFFFF;
  text-align: right;
  padding-right: 5px;
}

.choices_features_grid_row_v2 {
  display:               grid;
  grid-template-columns: 35px 323px;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.choices_features_grid_row_v2_col_1 {
  width:           100%;
  grid-column:     1;
  grid-row:        1;
}

.choices_features_grid_row_v2_col_2 {
  width:           100%;
  grid-column:     2;
  grid-row:        1;
  text-align:      left;
}

@media (max-width: 400px) {

  .choices_v2 {
    width:                 82vw;
  }

  .choices_features_grid_row_v2 {
    grid-template-columns: 8vw auto;
    grid-gap:              1.25vw;
  }

  .choices_features_grid_row_v2_col_2 {
    font-size: calc(1.5vw + 7px); /* Ajuste la taille de la police en fonction de la largeur de la fenêtre */
  }
}


.bottom_blank {
  width:           100%;
  grid-column:     1;
  grid-row:        3;
}

.discover_offer_column, {
  width:           100%;
  grid-column:     2;
  grid-row:        1;

  pading:                0;
  margin:                0;

  display:               grid;
  grid-template-columns: auto;
  grid-gap:              5px;
  grid-template-rows:    77px auto minmax(50px, auto);
}

.standard_offer_column, {
  width:           100%;
  grid-column:     3;
  grid-row:        1;

  pading:                0;
  margin:                0;

  display:               grid;
  grid-template-columns: auto;
  grid-gap:              5px;
  grid-template-rows:    77px auto minmax(50px, auto);
}

.premium_offer_column, {
  width:           100%;
  grid-column:     4;
  grid-row:        1;

  pading:                0;
  margin:                0;

  display:               grid;
  grid-template-columns: auto;
  grid-gap:              5px;
  grid-template-rows:    77px auto minmax(50px, auto);
}

.deco_current_offer {

  background-color: #333333;
  border: 4px solid #FFFF00;
  border-radius: 10px;
}

.deco_offer {

  border: 1px solid #FFFFFF;
  border-radius: 10px;
}

.deco_offer:hover {

  border: 4px solid #FFFFFF;
  border-radius: 10px;
}

.deco_offer_no_hover {

  border: 1px solid #FFFFFF;
  border-radius: 10px;
}

.deco_choosen_offer {

  background-color: #444444;
  border: 4px solid #FFFFFF;
  border-radius: 10px;
}

.choices_offer_title {

  width:           100%;
  grid-column:     1;
  grid-row:        1;

  pading:                0;
  margin:                0;

  display:               grid;
  color: #FFFFFF;
  text-align: center;
}

.choices_offer_content {

  width:           100%;
  grid-column:     1;
  grid-row:        2;

  pading:                0;
  margin:                0;

  display:               grid;
  color: #FFFFFF;
  grid-template-columns: auto;
  grid-template-rows:    repeat(6, 24px);

}

.choices_offer_state_action {

  min-height:       50px;
  width:           100%;
  grid-column:     1;
  grid-row:        3;

  pading:                0;
  margin:                0;

  display:               grid;
  color: #FFFFFF;

}

@media (max-width: 1050px) {

.choices_offer_state_action {

  pading-top:            10px;
  pading-bottom:         10px;


}
}

.icon {

  height: 23.5px;
  width: auto;
  margin: auto;
}

@media (max-width: 400px) {
  .icon {
    height: calc(5vw); /* 5.875vw correspond à 23.5px à 400px de largeur d'écran */
  }
}

.infos {
  color: #FFFFFF;
  width: fit-content;
  margin: auto;
}

.infos_red {
  color: #FF0000;
  width: fit-content;
  margin: auto;
}



.centered {

  width: fit-content;
  margin: auto;
}


.text_withdrawalPeriodWaiver {

  color:                 $block-text-color-1;
  font-size:             $block-text-size-1;
  text-align:            justify;
}

.grid_withdrawalPeriodWaiver
 {
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             $block-text-size-1;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}