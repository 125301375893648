@import '../../../../config.module.scss';

.root {

  margin:           auto;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       justify;
  
  height:           fit-content; 
  width:            100vw;
  
  background:      $page-background-color;
}

