.root {
 display: flex;
}

.trigger_normal{
  cursor: pointer;
  outline: none;
  color: #000000;

  border-radius: 5px 5px 5px 5px;
  border: 1px solid #000000;
  background: #EEEEEE;
}

.trigger_normal:disabled{
  cursor: not-allowed;
  pointer-events: all !important;
}

.trigger_normal:hover{
  color: #000000;
  border: 1px solid #FBFBFB;
  box-shadow: 0 0 5px rgba(255,255,255,.3),
              0 0 10px rgba(255,255,255,.2),
              0 0 15px rgba(255,255,255,.1),
              0 2px 0 white;
}

.trigger_normal:disabled:hover{
  color: #FFFFFF;
  border: 1px solid #7F7F7F;
  box-shadow: 0 0 5px rgba(127,127,127,.3),
              0 0 10px rgba(127,127,127,.2),
              0 0 15px rgba(127,127,127,.1),
              0 2px 0 black;
}

.trigger_pushed{
  cursor: pointer;
  outline: none;
  color: #FFFFFF;

  border-radius: 5px 5px 5px 5px;
  border: 1px solid #CCC;
  caret-color: #FFFFFF;
  background: linear-gradient(#999,#666);
  
}
